import React from 'react';

import { Layout, SEO } from 'components';
import { Careers } from 'containers';

const CareersPage = () => (
  <Layout>
    <SEO title='Careers' />
    <Careers />
  </Layout>
);

export default CareersPage;
